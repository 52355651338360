import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { AccordionStack } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAccordionStackType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokAccordionStack: FC<
  StoryblokComponentProps<StoryblokAccordionStackType>
> = ({ blok }) => {
  return (
    <AccordionStack {...storyblokEditable(blok)}>
      <StoryblokComponentsRenderer bloks={blok.content} />
    </AccordionStack>
  )
}
